<template>
	<div>
		<head-card>
			<buyRuleMod></buyRuleMod>
		</head-card>
		
	</div>
</template>
<script>
	import store from "@/store/index";
	import axios from 'axios';
	import config from '@/config/index'

	import {
		mapGetters
	} from "vuex"
	
	import headCard from "./components/headCard";
	import buyRuleMod from '@/components/buyRuleMod/buyRuleMod'

	export default {
		name: 'buyRule',
		data() {
			return {
			}
		},
		components: {
			buyRuleMod,
			headCard
		},
		created() {
			this.getPayInfo()
		},
		computed: {
			...mapGetters(['pagemsg', 'pagename'])
		},
		methods: {
			getPayInfo() {

				store.dispatch('getpaymentrulevalitemenu', {
					Id: this.$route.query.id
				}).then((res) => {

				}).catch((rej) => {

				})
			},
			
		},
	}
</script>

<style lang="less" scoped>
	
</style>
