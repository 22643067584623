<template>
  <div class="select-mall">
    <div class="mall-info">
			<div class="mall-info-wraper">
				<div class="left">
					<img src="@/assets/img/20211206154059.png" width="250px">
				</div>
				<div class="right">
					<span>{{memberName}}</span>
					<span>{{memberPhone}}</span>
					<span style="color: #409EEF;cursor: pointer;" @click="logout">退出登录</span>
				</div>
			</div>
		</div>
    <div style="margin-top:140px">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
export default {
  computed: {
    ...mapGetters([
      'loginState',
      'ShopName',
      'ShopLogo',
    ])
  },
  data () {
    return {
      memberName: '',
			memberPhone: '',
    }
  },
  created () {
    this.memberName = localStorage.getItem('mlmzpageName')
		this.memberPhone = localStorage.getItem('mlmzpagePhone')
  },
  methods: {
    logout() {
      this.$store.dispatch('LogOut').then(() => {
        this.$router.replace({
          path: '/login'
        })
      })
    },
  }
}
</script>

<style lang = "less" scoped>
  .select-mall{
		.mall-info{
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100px;
			box-shadow: 0 1px 10px 1px rgba(0,0,0,.1);
			display: flex;
			justify-content: center;
			z-index: 1000;
			background-color: #fff;
			
			.mall-info-wraper{
				display: flex;
				align-items: center;
				justify-content: space-between;
				
				min-width: 960px;
				max-width: 960px;
				
				.right{
					font-size: 14px;
					margin-top: 20px;
					
					span{
						padding-left: 20px;
					}
				}
			}
		}
		
		
		.mall-title{
			font-size: 14px;
			margin-top: 140px;
			margin-left: auto;
			margin-right: auto;
			min-width: 960px;
			max-width: 960px;
		}
		
		.mall-list{
			margin-top: 20px;
			margin-left: auto;
			margin-right: auto;
			min-width: 960px;
			max-width: 960px;
			
			display: flex;
			flex-wrap: wrap;
			
			
			.mall-item{
				position: relative;
				width: 300px;
				height: 150px;
				border: 1px solid #ddd;
				border-radius: 4px;
				padding: 30px 20px;
				box-sizing: border-box;
				margin-right: 20px;
				margin-bottom: 20px;
				overflow: hidden;
				cursor: pointer;
				
				.top{
					display: flex;
					
					img{
						flex: 0 0 auto;
						width: 40px;
						height: 40px;
						border-radius: 100%;
					}
					
					.info{
						flex: 1 1 auto;
						margin-left: 10px;
						
						.name{
							font-size: 16px;
							margin-top: 2px;
						}
						.time{
							color: #999;
							font-size: 12px;
							margin-top: 5px;
						}
					}
				}
			
				.btn{
					display: flex;
					justify-content: flex-end;
					color: #409EEF;
					font-size: 14px;
					margin-top: 30px;
				}
				.border{
					position: absolute;
					left: 0;
					width: 100%;
					bottom: 0;
					height: 5px;
					background-color: #409EEF;
				}
				.expire{
					display: flex;
					justify-content: flex-end;
					color: #999;
					font-size: 14px;
					margin-top: 30px;
				}
			}
			
			.mallItemIsExpire{
				background-color: #f6f6f6;
				opacity: .7;
				color: #999;
			}
		}
	}
</style>